<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
                                    YENİ KAYIT
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="YEDEK PARÇA TEKLİF/SİPARİŞ" entityName="bm_yedekparcateklifsiparis">
					</EntityHeader>
                    
                    <Message v-if="mainData.pesinMi == true" :closable="false">Seçili firmanın Ödeme koşulu PEŞİN'dir</Message>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifBasligi">Teklif Başlığı <span style="color:red">*</span></label>
								<InputText id="teklifBasligi" type="text" v-model="mainData.teklifBasligi" />
                                <span v-if="v$.mainData.teklifBasligi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.teklifBasligi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifRevizeNo">Teklif Revize No</label>
								<InputNumber id="teklifRevizeNo" v-model="mainData.teklifRevizeNo" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="0" :showButtons="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="musteriReferansNo">Müşteri Referans No</label>
								<InputText id="musteriReferansNo" type="text" v-model="mainData.musteriReferansNo" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifTarihi">Teklif Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="teklifTarihi" v-model="mainData.teklifTarihi" autocomplete="off" :showIcon="true" />
                                <span v-if="v$.mainData.teklifTarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.teklifTarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
                            </div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<EntityLookupPopup  id="tezgah" v-model="mainData.tezgahName" ref="entity_tezgah" 
									label="Tezgah" entityName="bm_unite" nameField="bm_serino" :state="true" :disabled="mainData.teklifBasligi == undefined"
									@itemSelected="tezgahSelected = $event" @itemCleared="tezgahSelected = null"
									parentFieldName="bm_kurulummusteriid" :parentId="firmaSelected"
                                    secondNameField="bm_urunid" secondNameFieldType="lookup"
									:CrmDataTable_options="tezgah_CrmDataTable_options">
								</EntityLookupPopup>
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="urun" v-model="mainData.urunName" ref="entity_urun" label="Ürün"
									entityName="product" nameField="name" :state="true"
									secondNameField="productnumber"
									@itemSelected="urunSelected = $event" @itemCleared="urunSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
									entityName="account" nameField="name" :state="true" :required="true"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
                                <span v-if="v$.mainData.firmaId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="ilgilikisi" v-model="mainData.ilgiliKisiName" ref="entity_ilgilikisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" :state="true"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="ilgilikisiSelected = $event"
									@itemCleared="ilgilikisiSelected = null">
								</EntityLookup>
							</div>
						</div>
						
						<div class="col-3">
							<div class="field p-fluid">
								<label for="siparisTarihi">Sipariş Tarihi</label>
								<Calendar showButtonBar id="siparisTarihi" v-model="mainData.siparisTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						
						<div class="col-3">
							<div class="field p-fluid">
								<label for="turu">Türü</label>
								<Dropdown id="turu" v-model="mainData.turu" :options="SM_turu" optionLabel="Value" optionValue="AttributeValue" placeholder="Türü" :showClear="true" :filter="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="fiyatTuru">Fiyat Türü</label>
								<Dropdown id="fiyatTuru" v-model="mainData.fiyatTuru" :options="SM_fiyatTuru" optionLabel="Value" optionValue="AttributeValue" placeholder="Fiyat Türü" :showClear="true" />
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<label for="urgencyCode">Urgency Code</label>
								<Dropdown id="urgencyCode" v-model="mainData.urgencyCode" :options="SM_urgencyCode" optionLabel="Value" optionValue="AttributeValue" placeholder="Urgency Code" :showClear="true" />
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<label for="alternatifkisi">Alternatif Kişi</label>
								<InputText id="alternatifkisi" type="text" v-model="mainData.alternatifKisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="vadeSuresi">Vade Süresi</label>
								<Dropdown id="vadeSuresi" v-model="mainData.vadeSuresi" :options="SM_vadeSuresi" optionLabel="Value" optionValue="AttributeValue" placeholder="Vade Süresi" :showClear="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="pesinMi">Peşin Mi?</label>
								<Dropdown id="pesinMi" v-model="mainData.pesinMi" :options="SM_pesinMi" optionLabel="Value" optionValue="AttributeValue" placeholder="Peşin Mi?" :showClear="true" @change="changePesinmi"  />
							</div>
						</div>
					</div>
                    <div class="grid">
                        <div class="col-4">
							<div class="field p-fluid">
								<label for="teslimKosullari">Teslim Koşulları</label>
								<Textarea id="teslimKosullari" rows="2" :autoResize="false" v-model="mainData.teslimKosullari" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="odemeKosullari">Ödeme Koşulları</label>
								<Textarea id="odemeKosullari" rows="2" :autoResize="false" v-model="mainData.odemeKosullari" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="genelAciklama">Genel Açıklama (Raporlarda çıkacaktır)</label>
								<Textarea id="genelAciklama" rows="2" :autoResize="false" v-model="mainData.genelAciklama" />
							</div>
						</div>
                        <div class="col-3" v-if="mainData.turu == 930710010">
							<div class="field p-fluid">
								<EntityLookup id="servisTalebi" v-model="mainData.servisTalebiName" ref="entity_servisTalebi"
									label="Servis Talebi" entityName="incident" nameField="ticketnumber" secondNameField="title"
									@itemSelected="servisTalebiSelected = $event" @itemCleared="servisTalebiSelected = null"
									parentFieldName="bm_uniteid" :parentId="tezgahSelected"></EntityLookup>
							</div>
						</div>
                    </div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>DETAYLAR</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaTarihi">Fatura Tarihi</label>
								<Calendar showButtonBar id="faturaTarihi" v-model="mainData.faturaTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaNo">Fatura No</label>
								<InputText id="faturaNo" type="text" v-model="mainData.faturaNo" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaToplami">Fatura Toplamı</label>
								<InputNumber id="faturaToplami" v-model="mainData.faturaToplami" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="0" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ymtrTeklifNo">YMTR Teklif No</label>
								<InputText id="ymtrTeklifNo" type="text" v-model="mainData.ymtrTeklifNo" />
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="paraBirimi" v-model="mainData.paraBirimiName" ref="entity_paraBirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true" :required="true"
									@itemSelected="paraBirimiSelected = $event" @itemCleared="paraBirimiSelected = null"></EntityLookup>
                                <span v-if="v$.mainData.paraBirimiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.paraBirimiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
                                </div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<label for="notAriza">Not Arıza</label>
								<Textarea id="notAriza" rows="1" :autoResize="false" v-model="mainData.notAriza" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            crmService: null,
            submitted: false,
            OBJECT_TYPE_CODE: 10011,
            mainData: {},
            teklifUrunleriData: [],
            nestedMenuitems: [
                {
                    label: "Kaydet",
                    icon: "pi pi-fw pi-save",
                    command: () => { this.OnSave(); },
                },
            ],
            kaydedildi: false,
            tezgah_CrmDataTable_options: {
				searchAttributes: ["bm_serino", "bm_urunidname"],
				forceSavedQueryId: "067671D1-4700-42C7-8B91-CE8EBAAFE78D"
            },
        };
    },
    async created() {
        this.crmService = new CrmService();
		window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.mainData.teklifTarihi = new Date();
        this.mainData.teklifRevizeNo = 0;
        this.mainData.durumAciklamasi = 1;
        this.mainData.pesinMi = false;

        setTimeout(() => {
			let paraBirimi = { "Value": "4f6220a0-6aeb-dc11-a742-000e0c3f151f", "Name": "Euro" };
			this.paraBirimiSelected = paraBirimi;
			this.$refs.entity_paraBirimi.setDefaultData(paraBirimi);
		}, 1000);
    },
    computed: {
        profileData() {
            return this.$store.getters.getProfile;
        },
        SM_satisYontemi: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_satisyontemi");
        },
        SM_durumAciklamasi: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "statuscode");
        },
        SM_turu: function () {
            return  [
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 5,
                    "Value": "Form Stok",
                    "DisplayOrder": 1,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 11,
                    "Value": "FS/METR Exch",
                    "DisplayOrder": 12,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 10,
                    "Value": "FS/METR Yeni",
                    "DisplayOrder": 13,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 2,
                    "Value": "Mazak Exch",
                    "DisplayOrder": 5,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 1,
                    "Value": "Mazak Yeni",
                    "DisplayOrder": 7,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 12,
                    "Value": "Studer",
                    "DisplayOrder": 9,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 6,
                    "Value": "Tamir",
                    "DisplayOrder": 10,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710001,
                    "Value": "Castrol",
                    "DisplayOrder": 15,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710002,
                    "Value": "Komatech",
                    "DisplayOrder": 16,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710003,
                    "Value": "Nomura",
                    "DisplayOrder": 17,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710008,
                    "Value": "Walter",
                    "DisplayOrder": 20,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710009,
                    "Value": "Barload",
                    "DisplayOrder": 21,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710010,
                    "Value": "Servis",
                    "DisplayOrder": 21,
                    "ObjectTypeCode": 10011
                },
            ];
        },
        SM_fiyatTuru: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_fiyatturu");
        },
        SM_vadeSuresi: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_vadesuresi");
        },
        SM_pesinMi: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_pesinmi", "bool");
        },
        SM_urgencyCode: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_urgencycode");
        },
        firmaSelected: {
            get: function () {
                if (this.mainData["firmaId"]) {
                    return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["firmaId"] = null;
                    this.mainData["firmaName"] = null;
                    this.tezgah_CrmDataTable_options["filterParent"] = [];
                }
                else {
                    this.mainData["firmaId"] = newValue.Value;
                    this.mainData["firmaName"] = newValue.Name;

                    this.tezgah_CrmDataTable_options["filterParent"] = [
						{ 'name': 'bm_kurulummusteriid', 'filterOperator': 'eq', 'value': newValue.Value },
					];

                    this.crmService.GetEntityById({
						"entityName": "account",
						"entityId": newValue.Value,
						"columnNames": ["paymenttermscode"]})
					.then(data => {
						if (data.jsonData != null) {
                            console.log(data.jsonData);
                            debugger;
                            if (data.jsonData["paymenttermscodeId"] == "0") { //Peşin
                                this.mainData["pesinMi"] = true;
                                this.mainData["odemeKosullari"] = "PEŞİN ÖDEME";
                            }
						}
					})
					.catch(error => console.log(error))
                }
            }
        },
        ilgilikisiSelected: {
            get: function () {
                if (this.mainData["ilgilikisiId"]) {
                    return { "Value": this.mainData["ilgilikisiId"], "Name": this.mainData["ilgilikisiName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["ilgilikisiId"] = null;
                    this.mainData["ilgilikisiName"] = null;
                }
                else {
                    this.mainData["ilgilikisiId"] = newValue.Value;
                    this.mainData["ilgilikisiName"] = newValue.Name;
                }
            }
        },
        tezgahSelected: {
			get: function () {
				if (this.mainData["tezgahId"]) {
					return { "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["tezgahId"] = null;
					this.mainData["tezgahName"] = null;
				}
				else {
					this.mainData["tezgahId"] = newValue.Value;
					this.mainData["tezgahName"] = newValue.Name;

					this.crmService.GetEntityById({
						"entityName": "bm_unite",
						"entityId": newValue.Value,
						"columnNames": ["bm_urunid", "bm_kurulummusteriid"]})
					.then(data => {
						if (data.jsonData != null) {
							this.$refs.entity_urun.setDefaultData({ "Value": data.jsonData["bm_urunid"], "Name": data.jsonData["bm_urunidName"] });
							this.mainData["urunId"] = data.jsonData["bm_urunid"];
							this.mainData["urunName"] = data.jsonData["bm_urunidName"];

                            this.$refs.entity_firma.setDefaultData({ "Value": data.jsonData["bm_kurulummusteriid"], "Name": data.jsonData["bm_kurulummusteriidName"] });
							this.mainData["firmaId"] = data.jsonData["bm_kurulummusteriid"];
							this.mainData["firmaName"] = data.jsonData["bm_kurulummusteriidName"];

                            if (this.kaydedildi == false) {
                                this.kaydedildi = true;
                                this.OnSave();
                            }
						}
					})
					.catch(error => console.log(error))
				}
			}
		},
        urunSelected: {
            get: function () {
                if (this.mainData["urunId"]) {
                    return { "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["urunId"] = null;
                    this.mainData["urunName"] = null;
                }
                else {
                    this.mainData["urunId"] = newValue.Value;
                    this.mainData["urunName"] = newValue.Name;
                }
            }
        },
        paraBirimiSelected: {
            get: function () {
                if (this.mainData["paraBirimiId"]) {
                    return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["paraBirimiId"] = null;
                    this.mainData["paraBirimiName"] = null;
                }
                else {
                    this.mainData["paraBirimiId"] = newValue.Value;
                    this.mainData["paraBirimiName"] = newValue.Name;
                }
            }
        },
        servisTalebiSelected: {
            get: function () {
                if (this.mainData["servisTalebiId"]) {
                    return { "Value": this.mainData["servisTalebiId"], "Name": this.mainData["servisTalebiName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["servisTalebiId"] = null;
                    this.mainData["servisTalebiName"] = null;
                }
                else {
                    this.mainData["servisTalebiId"] = newValue.Value;
                    this.mainData["servisTalebiName"] = newValue.Name;
                }
            }
        },
    },
    methods: {
        async OnSave() {
            debugger;
            this.submitted = true;
            this.v$.$touch();

            if (this.v$.$invalid) {
                this.v$.$errors.forEach(element => {
                    this.$toast.add({ severity: "error", summary: "Eksik alanlar var.", detail: element.$message, life: 3500 });
                });
            }
            else {
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});

                try {
                    const response = await this.crmService.getYedekParcaTeklifSiparisCreate(this.mainData);
                    debugger;
                    if (response) {
                        if (response.hata == true) {
                            this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                        }else {
                            this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
                            if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
                            
							setTimeout(() => {
								this.$router.push({ name: 'bm_yedekparcateklifsiparis', params: { id: response.olusturulanId } });
							}, 500);
                        }
                    }
                } catch (error) {
                    this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
                }
				finally {
					loader.hide();
				}
            }
        },
        changePesinmi(event){
            debugger;
            if (event.value) {
                this.mainData.odemeKosullari = "PEŞİN ÖDEME";
            }
            else {
                this.mainData.odemeKosullari = "";
            }
        },
        formatNumber(value) {
            debugger;
            if (value != undefined) {
                return value.toLocaleString("tr-TR");
            }
        },
        getCurrency() {
            let paraBirimi = "";
            switch (this.mainData.paraBirimiName) {
                case "Dolar":
                    paraBirimi = " $";
                    break;
                case "Euro":
                    paraBirimi = " €";
                    break;
                case "İsviçre Frankı":
                    paraBirimi = " SFr.";
                    break;
                case "Türk Lirası":
                    paraBirimi = " ₺";
                    break;
                case "Yen":
                    paraBirimi = " ¥";
                    break;
                default:
                    break;
            }
            return paraBirimi;
        },
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
    },
    watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Y.P. Teklif / Sipariş');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
    validations() {
        return {
            mainData: {
                teklifBasligi: {
                    required: helpers.withMessage('Teklif Başlığı alanı dolu olmalıdır!', required),
                },
                teklifTarihi: {
                    required: helpers.withMessage('Teklif Tarihi alanı dolu olmalıdır!', required),
                },
                paraBirimiId: {
                    required: helpers.withMessage('Para Birimi alanı dolu olmalıdır!', required),
                },
                firmaId: {
                    required: helpers.withMessage('Firma alanı dolu olmalıdır!', required),
                }
            },
        };
    },
}
</script>

<style lang="scss" scoped>
</style>
